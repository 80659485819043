<template lang="html">
  <slot></slot>
</template>
<script>
import { onMounted, onUnmounted } from "vue";
export default {
  components: {},
  setup() {
    onMounted(() => {
      document.body.classList.add("landing-body");
    })

    onUnmounted(() => {
      document.body.classList.remove("landing-body");
    })
  }
};
</script>
<style lang=""></style>
